import http from '@/utils/http';

/**
 * 字典
 */
export default {
  getList: {
    p: 'post,/system/dicttype/getlist',
    r: () => http({ url: '/system/dicttype/getlist', method: 'post' })
  },
  add: {
    p: 'post,/system/dicttype/add',
    r: (data) => http({ url: '/system/dicttype/add', method: 'post', data })
  },
  update: {
    p: 'post,/system/dicttype/update',
    r: (data) => http({ url: '/system/dicttype/update', method: 'post', data })
  },
  del: {
    p: 'post,/system/dicttype/delete',
    r: (data) => http({ url: '/system/dicttype/delete', method: 'post', data })
  }
};
