<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @row-save="handleSubmit"
      @row-update="rowUpdate"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
    <template slot="dict_type" slot-scope="scope">
      <el-link type="primary" @click="clickLink(scope.row)">{{scope.row.dict_type}}</el-link>
    </template>
    </avue-crud>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
